<template>
  <v-app>
    <!-- alert  -->
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <!-- end of alert  -->
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Payment Schedule</h1>
        </div>
      <!-- <v-table
        loading
        :headers="getPaymentHeaders"
        :items="getPaymentData"
      ></v-table> -->

      <div class="pl-5">
        <v-row>
          <v-col cols="4" class="pr-4">
            <v-autocomplete
            :items="contractEmployer"
            v-model="formData.ContractingCompanyId"
            outlined
            dense
            item-text="name"
            label="Contracting Company"
            item-value="id"
          ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <SelectField
                :items="salaryPeriods"
                v-model="formData.SalaryPeriodtId"
                :label="'Salary Cycle'"
                :itemText="'description'"
                :itemValue="'id'"
              />
          </v-col>
          <v-col cols="2" class="text-left">
            <v-btn
                    small
                    class="btn btnSubmit"
                    @click.prevent="filter"
                  >Submit</v-btn>
          </v-col>
        </v-row>
      </div>

      <div class="payment-table">
        <v-simple-table
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>Company Name</th>
                <th style="min-width: 1%;
    white-space: nowrap;">Personnel Name</th>
                <th>Salary Period</th>
                <th v-for="item in firstPaymentItems" :key="item">{{item.description}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in paymentData" :key="payment">
                <td style="min-width: 1%; white-space: nowrap;">{{payment.companyName}}</td>
                <td style="min-width: 1%; white-space: nowrap;">{{payment.employeeName}}</td>
                <td>{{payment.salaryPeriodDescription}}</td>
                <td v-for="payItem in payment.payItems" :key="payItem">
                  {{formatAmount(payItem.amount)}}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- <v-data-table
      :headers="paymentScheduleHeader"
      :items ="paymentSchedule"
      >
        <template v-slot:item.monthBasic="{ item }">
      <div class="actionBtn">
        <p>{{item.payItems[0].amount}}</p>
      </div>
    </template>
      </v-data-table> -->
      </div>
      <div v-if="paymentData.length > 0" class="col-md-12 mt-2 ml-auto text-right">
        <export-excel
          :data="paymentSchedule"
          :fields="exportFields"
          type="xls"
          worksheet="paymentSchedule"
          name="paymentSchedule.xls"
        >
          <Button :label="'Export to Excel'" :btnType="'Submit'" />
        </export-excel>
      </div>
    </div>
  </v-app>
</template>
<script>
import { paymentScheduleService, contractEmployerService, salaryPeriodService } from '@/services'
import Button from '@/components/ui/form/Button.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
export default {
  components: {
    Button,
    SelectField
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isLoading: false,
      id: '',
      paymentSchedule: [],
      paymentHeaders: [],
      paymentData: [],
      exportFields: {},
      firstPaymentItems: [],
      contractEmployer: [],
      salaryPeriods: [],
      formData: {
        'ContractingCompanyId': '0',
        'SalaryPeriodtId': '0'
      }
    }
  },

  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    close () {

    },
    process () {

    },
    formatAmount (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    loadPaymentSchedule (data) {
      paymentScheduleService.loadPaymentSchedules(data).then(result => {
        const data = result.data
        this.paymentData = data
        const rows = []
        const headers = [
          {
            text: 'Company Name',
            value: 'companyName'
          },
          {
            text: 'Employee Name',
            value: 'employeeName'
          },
          {
            text: 'Salary Period',
            value: 'salaryPeriodDescription'
          }
        ]

        const exportColumns = {
          'Company Name': 'companyName',
          'Employee Name': 'employeeName',
          'Salary Period': 'salaryPeriodDescription'
        }

        if (data.length > 0) {
          console.clear()
          const firstData = data[0]
          this.firstPaymentItems = firstData.payItems
          // const paymentRows1 = {}
          firstData.payItems.forEach(item => {
            headers.push({
              text: item.description.toString().trim(),
              value: item.description.toString().trim().replace(/\s/g, '')
            })

            Object.assign(exportColumns, { [item.description.toString()]: item.description.toString().trim().replace(/\s/g, '') })
          })

          this.paymentHeaders = headers
          this.exportFields = exportColumns

          data.forEach(item => {
            const paymentRows = {}
            const employee = {
              companyName: item.companyName,
              employeeName: item.employeeName,
              salaryPeriodDescription: item.salaryPeriodDescription
            }

            item.payItems.forEach(payItem => {
              let paymentName = payItem.description.toString().trim()
              paymentName = paymentName.replace(/\s/g, '')
              const payment = { [paymentName]: this.formatAmount(payItem.amount) }
              Object.assign(paymentRows, payment)
            })

            Object.assign(employee, paymentRows)
            rows.push(employee)
          })
        }
        // this.paymentSchedule = result.data
        this.paymentSchedule = rows
        // console.log('🚀 ~ file: index.vue ~ line 145 ~ paymentScheduleService.loadPaymentSchedule ~ rows', rows)
      }).catch(() => {
        this.showAlertMessage('Unable to load payment schedules', 'error')
      })
    },
    getData (name, array) {
      const newVal = array.find(arg => arg.description === name)
      return newVal.amount
    },
    getContractEmployer () {
      contractEmployerService.getAllContractEmployers().then((result) => {
        this.contractEmployer = result.data.items
        this.contractEmployer.unshift({ 'id': '0', 'name': 'All' })
      })
    },
    loadSalaryPeriod () {
      salaryPeriodService.getAllSalaryPeriod().then(result => {
        this.salaryPeriods = result.data
        this.salaryPeriods.unshift({ 'id': '0', 'description': 'All' })
      }).catch(error => {
        console.log(error)
      })
    },
    filter () {
      let ContractingCompanyId = parseInt(this.formData.ContractingCompanyId) || 0
      let SalaryPeriodtId = parseInt(this.formData.SalaryPeriodtId) || 0
      this.loadPaymentSchedule({ ContractingCompanyId, SalaryPeriodtId })
    }

  },
  created () {
    this.getContractEmployer()
    this.loadSalaryPeriod()
    this.loadPaymentSchedule({
      'ContractingCompanyId': 0,
      'SalaryPeriodtId': 0
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.loadPaymentSchedule(to.params.id)
    next()
  },
  watch: {
    getPaymentHeaders () {
      return this.paymentHeaders
    },
    getPaymentData () {
      return this.paymentSchedule
    }
  }
}

</script>
<style scoped>
.payment-table {
  max-height: 500px;
  overflow: auto;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
.btn{
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "GothamBold",Arial,sans-serif;
    font-size: 14px;
    padding: 18.5px 20px  !important;
    text-decoration: none;
    border-bottom-style: none;
    border-radius: 3;
    margin-left: 4px;
}
.btnSubmit{
  background: #0066b2 !important;
}
</style>
